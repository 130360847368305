<template>
  <div class="cont">
    <Header active_tab="profile"/>
    <hr />
    <table class="w-50 mx-auto my-5 table table-bordered table-striped ">
      <thead>
        <tr class="thead">
          <td>Contest of</td>
          <td>{{ user }}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Number of Test Attempted</td>
          <td>{{ numberOfTestAttempted }}</td>
        </tr>
        <tr>
          <td>Best Rank</td>
          <td>
            {{ bestRank.length == 0 ? 0 : Math.min(...bestRank) }}
          </td>
        </tr>
        <tr>
          <td>Best Percentile</td>
          <td>
            {{ bestPercentile.length == 0 ? 0 : Math.max(...bestPercentile) }}
          </td>
        </tr>
      </tbody>
    </table>
    <h5 class="plot-title">Plot of Rank</h5>
    <div class="row" id="mycontainer">
      <div class="col canvas-container change-color">
        <canvas class="my-chart" id="myChart"></canvas>
      </div>
    </div>
    <h5 class="plot-title">Plot of Percentage Score</h5>
    <div class="row" id="mycontainer2">
      <div class="col canvas-container">
        <canvas class="my-chart" id="myChart2"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import db from "../firebase.js";
import Header from "@/components/Header";
import store from "../store";
import { getPastPerformance } from "@/Utils/global";

export default {
  name: "PastPeformance",
  components: {
    Header
  },
  mounted() {
    this.userId = store.state.user.data.uid;
    this.loadPastPerformanceData();
    store.state.loading = false;
  },
  data() {
    return {
      numberOfTestAttempted: 0,
      bestRank: [],
      bestPercentile: [],
      user: "",
      userId: "",
      percentiles: [],
      ranks: []
    };
  },
  methods: {
    async loadPastPerformanceData() {
      var data = await getPastPerformance(this.userId);
      let pastData = data.data();

      this.numberOfTestAttempted = pastData.number_of_test_given;
      this.bestRank = pastData.best_rank;
      this.bestPercentile = pastData.best_percentile;
      this.user = pastData.name;

      this.plotGraph("myChart2", pastData.best_percentile, "Percentile ");
      this.plotGraph("myChart", pastData.best_rank, "Rank");
    },
    plotGraph(name, data, scorePattern) {
      var ctx = document.getElementById(name).getContext("2d");
      var chart = new Chart(ctx, {
        // The type of chart we want to create
        type: "line",
        // The data for our dataset
        data: {
          labels: [1, 2, 3, 4, 5, 6, 7, 8, 9],
          datasets: [
            {
              label: scorePattern,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgb(255, 99, 132)",
              borderWidth: 2,
              hoverBackgroundColor: "rgba(255,99,132,0.4)",
              hoverBorderColor: "rgba(255,99,132,1)",
              fill: false,
              data: data
            }
          ]
        },

        // Configuration options go here
        options: {
          responsive: true,
          maintainAspectRatio: false
        }
      });
    }
  }
};
</script>

<style scoped>
#mycontainer,
#mycontainer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.canvas-container {
  overflow: hidden;
  margin-right: 20px;
  margin-left: 20px;
  white-space: nowrap;

  height: 400px;
  width: 600px;
  max-width: 80%;
}
.change-color {
  background-color: white;
}
.my-chart {
  background-color: white;
}
.plot-title {
  justify-content: center;
  display: flex;
  margin-top: 30px;
  color: grey;
  font-family: sans-serif;
}
</style>
